export const modules = {
  WxuHeaderMobile: { // Left as an example. Can be deleted if not needed for this service.
    clientLoader: () => import(/* webpackChunkName: "HeaderMobile" */'@wxu/modules/src/HeaderMobile/client.loader').then(module => module.clientLoader),
  },
  WxuNavigation: {
    clientLoader: () => import(/* webpackChunkName: "Navigation" */'@wxu/modules/src/Navigation/client.loader').then(module => module.clientLoader),
  },
  WxuFooter: {
    clientLoader: () => import(/* webpackChunkName: "Footer" */'@wxu/modules/src/Footer/client.loader').then(module => module.clientLoader),
  },
  WxuLocalsuiteNav: {
    clientLoader: () => import(/* webpackChunkName: "LocalsuiteNav" */'@wxu/modules/src/LocalsuiteNav/client.loader').then(module => module.clientLoader),
  },
  WxuHeaderLargeScreen: {
    clientLoader: () => import(/* webpackChunkName: "HeaderLargeScreen" */'@wxu/modules/src/HeaderLargeScreen/client.loader').then(module => module.clientLoader),
  },
  WxuSavedLocations: {
    clientLoader: () => import(/* webpackChunkName: "SavedLocations" */'@wxu/modules/src/SavedLocations/client.loader').then(module => module.clientLoader),
  },
  WxuNotFound: {
    clientLoader: () => import(/* webpackChunkName: "NotFound" */'@wxu/modules/src/NotFound/client.loader').then(module => module.clientLoader),
  },
  WxuPrivacyDataNotice: {
    clientLoader: () => import(/* webpackChunkName: "PrivacyDataNotice" */'@wxu/modules/src/PrivacyDataNotice/client.loader').then(module => module.clientLoader),
  },
  WxuPrivacyFeedCard: {
    clientLoader: () => import(/* webpackChunkName: "PrivacyFeedCard" */'@wxu/modules/src/PrivacyFeedCard/client.loader').then(module => module.clientLoader),
  },
  WxuCookiesDisabled: {
    clientLoader: () => import(/* webpackChunkName: "CookiesDisabled" */'@wxu/modules/src/CookiesDisabled/client.loader').then(module => module.clientLoader),
  },
  WxuPollCard: {
    clientLoader: () => import(/* webpackChunkName: "InsightBentoCard" */'@wxu/modules/src/Poll/client.loader').then(module => module.clientLoader),
  },
  WxuPrivacyConsentModal: {
    clientLoader: () => import(/* webpackChunkName: "WxuPrivacyConsentModal" */'@wxu/modules/src/PrivacyConsentModal/client.loader').then(module => module.clientLoader),
  },
  // APPEND_MODULE
};
